import React from 'react';
import Typography from '../../../../../sharedComponents/typography';

export const renderEmail = (row) => {
    return <div className="typography-wrapper link mouse-pointer">{row?.email ?? '-'}</div>;
};

export const renderHS110s = (row) => {
    return <Typography.Body size={Typography.Sizes.md}>{row.HS110s ?? '-'}</Typography.Body>;
};

export const renderTimezone = (row) => {
    return <Typography.Body size={Typography.Sizes.md}>{row?.timezone ?? '-'}</Typography.Body>;
};

export const renderKP115s = (row) => {
    return <Typography.Body size={Typography.Sizes.md}>{row?.KP115s ?? '-'}</Typography.Body>;
};

export const renderHS300s = (row) => {
    return <Typography.Body size={Typography.Sizes.md}>{row?.HS300s ?? '-'}</Typography.Body>;
};

export const renderSocket = (row) => {
    return <Typography.Body size={Typography.Sizes.md}>{row?.Socket ?? '-'}</Typography.Body>;
};

export const renderRemainingCapacity = (row) => {
    return <Typography.Body size={Typography.Sizes.md}>{row?.Remaining_Capacity ?? '-'}</Typography.Body>;
};

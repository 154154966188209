import { updateMultipleEquipments } from '../../../services/Network';
import axiosInstance from '../../../services/axiosInstance';

export const fetchUpdateMultipleEquipments = (bldgId, equipmentIds, fieldsToUpdate) => {
    const payload = {
        equipment_ids: equipmentIds,
        update: fieldsToUpdate,
    };

    return axiosInstance.post(`${updateMultipleEquipments}?building_id=${bldgId}`, payload).then((res) => res.data);
};

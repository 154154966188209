import colors from '../../assets/scss/_colors.scss';

export const ACTIONS = [
    { value: 'pending_review', label: 'Pending Review' },
    { value: 'expert_reviewed', label: 'Expert Reviewed' },
    { value: 'in_process', label: 'In Process' },
    { value: 'completed', label: 'Completed' },
    { value: 'archived', label: 'Archived' },
];

export const ACTIONS_DICTIONARY = {
    pending_review: 'Pending Review',
    expert_reviewed: 'Expert Reviewed',
    in_process: 'In Process',
    completed: 'Completed',
    archived: 'Archived',
};

export const CATEGORIES = [
    { label: 'Machine Health', value: 'machine_health' },
    { label: 'Energy Optimization', value: 'energy_optimisation' },
    { label: 'Carbon Optimization', value: 'carbon_optimisation' },
];

export const CATEGORIES_DICTIONARY = {
    machine_health: 'Machine Health',
    energy_optimisation: 'Energy Optimization',
    carbon_optimisation: 'Carbon Optimization',
};

export const CATEGORIES_COLORS = {
    machine_health: colors.datavizMain1,
    energy_optimisation: colors.datavizMain2,
    carbon_optimisation: colors.datavizMain3,
};

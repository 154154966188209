import { Store } from 'pullstate';

export const ComponentStore = new Store({
    parent: '',
});

//  ---------- Options: ----------
// portfolio
// buildings
// carbon-portfolio
// carbon-buildings
// control
// explore
// account
// building-settings
// alerts
// superuser-account

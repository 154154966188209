const FILTER_TYPES = Object.freeze({
    MULTISELECT: 'MULTISELECT',
    RANGE_SELECTOR: 'RANGE_SELECTOR',
    LAST_UPDATED_SELECTOR: 'LAST_UPDATED_SELECTOR',
    DATE_SELECTOR: 'DATE_SELECTOR',
    LOCATION_SELECTOR: 'LOCATION_SELECTOR',
});

const LOCAL_STORAGE = Object.freeze({
    EXCLUDED_HEADERS: 'EXCLUDED_HEADERS',
    ORDER_HEADERS: 'ORDER_HEADERS',
});

const SORT_TYPES = [null, 'ace', 'dce'];

export { FILTER_TYPES, LOCAL_STORAGE, SORT_TYPES };

import React, { useState } from 'react';

import { Modal } from 'reactstrap';
import Equipment from './Equipment';
import { useParams } from 'react-router-dom';
import { fetchUpdateMultipleEquipments } from './services';
import { UserStore } from '../../../store/UserStore';

const EquipmentServedList = ({ showModal, closeModal, selectedSpaceId, updateTableData }) => {
    const { bldgId } = useParams();

    const [equipmentsToUpdate, setEquipmentsToUpdate] = useState([]);
    const [equipmentsUpdating, setEquipmentsUpdating] = useState(false);

    const showSuccessMessage = () =>
        UserStore.update((s) => {
            s.showNotification = true;
            s.notificationMessage = 'The selected equipment successfully were linked to the current space.';
            s.notificationType = 'success';
        });

    const showFailMessage = () =>
        UserStore.update((s) => {
            s.showNotification = true;
            s.notificationMessage =
                'Unable to link multiple equipment to the space to server.\nPlease, open console for error details.';
            s.notificationType = 'error';
        });

    const handleUpdateBtn = async () => {
        setEquipmentsUpdating(true);

        try {
            const equipmentsToUpdateIds = equipmentsToUpdate.map((equipment) => equipment.equipments_id);
            const newEquipmentsBody = {
                location_served: [selectedSpaceId],
            };

            const response = await fetchUpdateMultipleEquipments(bldgId, equipmentsToUpdateIds, newEquipmentsBody);

            if (response?.error)
                throw new Error(`Manually Created Error: Server returned an error: ${response?.message}`);

            showSuccessMessage();
        } catch (error) {
            console.log(
                'ERROR during updating multiple equipment to link to the new location (location_served)>>>>>',
                error
            );
            showFailMessage();
        }

        setEquipmentsUpdating(false);
        updateTableData();
        setEquipmentsToUpdate([]);
        closeModal();
    };

    const handleCheckbox = (equipmentToAdd) => {
        setEquipmentsToUpdate((equipments) => {
            const newEquipments = [...equipments];
            const equipmentIndex = newEquipments.findIndex(
                (equipment) => equipment.equipments_id === equipmentToAdd.equipments_id
            );

            if (equipmentIndex !== -1) {
                newEquipments.splice(equipmentIndex, 1);
            } else {
                newEquipments.push(equipmentToAdd);
            }

            return newEquipments;
        });
    };

    const handleCheckAll = (equipments) => {
        setEquipmentsToUpdate([...equipments]);
    };

    return (
        <Modal isOpen={showModal} toggle={closeModal} className="modal-fullscreen">
            <div className="m-3">
                <Equipment
                    servedBySelectedSpaceId={true}
                    selectedSpaceId={selectedSpaceId}
                    showCheckbox={true}
                    handleCheckbox={handleCheckbox}
                    handleUpdateBtn={handleUpdateBtn}
                    checkedElements={equipmentsToUpdate}
                    handleCheckAll={handleCheckAll}
                    isUpdateBtnUpdating={equipmentsUpdating}
                />
            </div>
        </Modal>
    );
};

export default EquipmentServedList;

import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'reactstrap';

import Brick from '../../../../sharedComponents/brick';
import Checkbox from '../../../../sharedComponents/form/checkbox/Checkbox';
import Button from '../../../../sharedComponents/button/Button';
import Typography from '../../../../sharedComponents/typography';

import colorPalette from '../../../../assets/scss/_colors.scss';
import './style.css';

const UnLinkModal = ({ showUnlink, handleUnLinkClose, error, message, handleUnlink, linkedAccount = [] }) => {
    const [selectedAccountIds, setSelectedAccountIds] = useState([]);

    const handleRecordSelect = (value, accountId) => {
        if (!accountId) return;

        if (value === 'true') {
            const updatedList = selectedAccountIds.filter((id) => id !== accountId);
            setSelectedAccountIds(updatedList);
        } else if (value === 'false') {
            setSelectedAccountIds((prevAccountIds) => [...prevAccountIds, accountId]);
        }
    };

    useEffect(() => {
        if (!showUnlink) setSelectedAccountIds([]);
    }, [showUnlink]);

    return (
        <Modal show={showUnlink} onHide={handleUnLinkClose} centered backdrop="static" keyboard={false}>
            <Modal.Body className="p-4">
                <Typography.Header size={Typography.Sizes.lg}>{`Unlink Account`}</Typography.Header>

                <Brick sizeInRem={2} />

                <Typography.Body size={Typography.Sizes.lg}>
                    {`Choose the account that you would like to unlink from this building.`}
                </Typography.Body>

                <Brick sizeInRem={1.5} />

                {error && (
                    <>
                        <Alert color="danger" isOpen={error}>
                            {message}
                        </Alert>
                        <Brick sizeInRem={0.5} />
                    </>
                )}

                {linkedAccount.length !== 0 ? (
                    <div className="pl-2 pr-2">
                        {linkedAccount.map((record, index) => {
                            return (
                                <div
                                    id={index}
                                    className="d-flex align-items-center"
                                    style={{
                                        gap: '0.25rem',
                                        marginBottom: linkedAccount.length === index + 1 ? '0rem' : '1rem',
                                    }}>
                                    <Checkbox
                                        size={Checkbox.Sizes.sm}
                                        value={selectedAccountIds.includes(record?.id)}
                                        checked={selectedAccountIds.includes(record?.id)}
                                        onChange={(e) => {
                                            handleRecordSelect(e.target.value, record?.id);
                                        }}
                                    />
                                    <div
                                        className="typography-wrapper mouse-pointer"
                                        style={{ color: colorPalette.datavizBlue500 }}>
                                        {record?.email ?? '-'}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <Typography.Body size={Typography.Sizes.md}>{`No account found.`}</Typography.Body>
                )}
            </Modal.Body>
            <Modal.Footer className="pb-4 pr-4">
                <Button
                    label="Cancel"
                    size={Button.Sizes.lg}
                    type={Button.Type.secondaryGrey}
                    onClick={handleUnLinkClose}
                />
                <Button
                    label={'Unlink'}
                    size={Button.Sizes.lg}
                    type={Button.Type.primaryDistructive}
                    onClick={() => {
                        if (selectedAccountIds && selectedAccountIds.length !== 0) {
                            handleUnlink(selectedAccountIds);
                        }
                    }}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default UnLinkModal;

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useAtom } from 'jotai';
import Skeleton from 'react-loading-skeleton';
import { Spinner, UncontrolledTooltip } from 'reactstrap';

import { userPermissionData } from '../../../../store/globalState';

import Typography from '../../../../sharedComponents/typography';
import Brick from '../../../../sharedComponents/brick';
import { Button } from '../../../../sharedComponents/button';
import { Notification } from '../../../../sharedComponents/notification/Notification';

import { ReactComponent as AttachedSVG } from '../../../../assets/icon/active-devices/attached.svg';
import { ReactComponent as SocketSVG } from '../../../../assets/icon/active-devices/socket.svg';
import { ReactComponent as OnlineSVG } from '../../../../assets/icon/active-devices/online.svg';
import { ReactComponent as OfflineSVG } from '../../../../assets/icon/active-devices/offline.svg';
import { ReactComponent as CircleXmarkSVG } from '../../../../assets/icon/circle-xmark.svg';

import { ReactComponent as RotateSVG } from '../../../../assets/icon/rotate.svg';
import { ReactComponent as RotateRightSVG } from '../../../../assets/icon/rotate-right.svg';

import { ReactComponent as WifiSVG } from '../../../../sharedComponents/assets/icons/wifi.svg';
import { ReactComponent as WifiSlashSVG } from '../../../../sharedComponents/assets/icons/wifislash.svg';

import {
    fetchDeviceStatus,
    fetchSocketRelayStatus,
    toggleSmartPlugSocket,
} from '../../../settings/active-devices/services';
import { getDeviceSensorsList } from './services';

import 'react-loading-skeleton/dist/skeleton.css';
import colorPalette from '../../../../assets/scss/_colors.scss';
import './styles.scss';

const SmartPlugDevice = (props) => {
    const { bldgId, equipmentObj = {}, setNotifyObj } = props;

    const [userPermission] = useAtom(userPermissionData);

    const isUserAdmin = userPermission?.is_admin ?? false;
    const isSuperUser = userPermission?.is_superuser ?? false;
    const isSuperAdmin = isUserAdmin || isSuperUser;
    const canUserEdit = userPermission?.permissions?.permissions?.advanced_active_device_permission?.edit ?? false;
    const canUserToggleOnOffDevice = isSuperAdmin || canUserEdit;

    const [deviceStatus, setDeviceStatus] = useState(false);
    const [isFetchingDeviceStatus, setFetchingDeviceStatus] = useState(false);

    const [socketStatusList, setSocketStatusList] = useState([]);
    const [isFetchingSocketById, setFetchingSocketById] = useState(null); // Fetch by Socket ID
    const [isFetchingSensors, setFetchingSensors] = useState(false);
    const [isFetchingAllSocketStatus, setFetchingAllSocketStatus] = useState(false);

    const [socketSensorsList, setSocketSensorsList] = useState([]);

    const socketSensorsWithStatus = socketSensorsList.map((el) => {
        const socketStatusObj = socketStatusList.find((obj) => obj?.socket_id === el?.socket_id);
        return {
            ...el,
            socket_status: socketStatusObj?.socket_status ?? null,
            is_on: socketStatusObj?.is_on ?? false,
        };
    });

    const DeviceStatusSVG = deviceStatus ? WifiSVG : WifiSlashSVG;
    const customClassName = socketSensorsWithStatus.length === 1 ? 'd-flex justify-content-center' : '';
    const socketStatusClassName = isFetchingAllSocketStatus ? `clockwise-animation` : ``;

    const isStatusCanBeChecked =
        socketSensorsWithStatus.length !== 0 && !isFetchingAllSocketStatus && !isFetchingSocketById;

    const fetchSmartPlugDeviceStatus = async (bldg_id, device_id) => {
        if (!bldg_id || !device_id) return;

        setFetchingDeviceStatus(true);
        setDeviceStatus(false);

        const params = `?building_id=${bldg_id}&device_ids=${device_id}`;

        await fetchDeviceStatus(params)
            .then((res) => {
                const { success = false, data = [] } = res?.data;
                if (success && data[0]) setDeviceStatus(data[0]?.is_on ?? false);
            })
            .catch(() => {})
            .finally(() => {
                setFetchingDeviceStatus(false);
            });
    };

    const fetchDeviceSensorsList = async (device_id) => {
        if (!device_id) return;

        setFetchingSensors(true);
        const params = `?device_id=${device_id}`;

        await getDeviceSensorsList(params)
            .then((res) => {
                const { data = [] } = res;
                setSocketSensorsList(data);
            })
            .catch((error) => {})
            .finally(() => {
                setFetchingSensors(false);
            });
    };

    const fetchSmartPlugSensorStatus = async (bldg_id, sensors_list = [], socketID = 'all', sensorId) => {
        if (!bldg_id) return;
        if (socketID !== 'all' && !sensorId) return;
        if (socketID === 'all' && sensors_list.length === 0) return;

        if (socketID === 'all') {
            setFetchingAllSocketStatus(true);
            setSocketStatusList([]);
        } else {
            setFetchingSocketById(socketID);
        }

        const sensorIds = encodeURIComponent(sensors_list.map(({ id }) => id).join('+'));
        const params = `?building_id=${bldg_id}&sensor_ids=${socketID === 'all' ? sensorIds : sensorId}`;

        await fetchSocketRelayStatus(params)
            .then((res) => {
                const { success = false, data = [] } = res?.data;

                if (success && data.length !== 0) {
                    if (socketID === 'all') {
                        setSocketStatusList(data);
                    } else {
                        if (socketStatusList.length === 0) {
                            setSocketStatusList(data);
                        } else {
                            let clonedList = _.cloneDeep(socketStatusList);
                            let socketStatusModified = false;

                            // Check if any element in 'data' matches an element in 'clonedList'
                            data.forEach((socket) => {
                                const existingSocket = clonedList.find((el) => el?.socket_id === socket?.socket_id);
                                if (existingSocket) {
                                    socketStatusModified = true;
                                    existingSocket.socket_status = socket.socket_status ?? false;
                                }
                            });

                            // If no match found, add the first element of 'data' to 'clonedList'
                            if (!socketStatusModified && data.length > 0) {
                                clonedList.push(data[0]);
                            }

                            setSocketStatusList(clonedList);
                        }
                    }
                } else {
                    setNotifyObj({
                        type: Notification.Types.error,
                        description: `Unable to fetch Socket status.`,
                    });
                    setSocketStatusList([]);
                }
            })
            .catch(() => {
                setNotifyObj({
                    type: Notification.Types.error,
                    description: `Failed to fetch Socket status due to Internal Server Error.`,
                });
                setSocketStatusList([]);
            })
            .finally(() => {
                if (socketID === 'all') {
                    setFetchingAllSocketStatus(false);
                } else {
                    setFetchingSocketById(null);
                }
            });
    };

    const handleSmartPlugSocketSwitch = async (
        bldg_id,
        sensorId,
        toggleOn = false,
        index,
        canUserToggleOnOffDevice
    ) => {
        if (!bldg_id || !sensorId) return;

        if (!canUserToggleOnOffDevice) {
            setNotifyObj({
                type: Notification.Types.error,
                description: `You don't have permission to turn the socket on or off.`,
            });
            return;
        }

        setFetchingSocketById(index);

        const params = `?building_id=${bldg_id}&toggle=${toggleOn ? 'on' : 'off'}`;
        const payload = {
            sensor_ids: [sensorId],
        };

        await toggleSmartPlugSocket(params, payload)
            .then((res) => {
                const { success = false, data = [], message } = res?.data;
                if (success) {
                    fetchSmartPlugSensorStatus(bldg_id, [], index, sensorId);
                    setNotifyObj({
                        type: Notification.Types.success,
                        description: 'Socket Toggle On / Off successfully.',
                    });
                } else {
                    setNotifyObj({
                        type: Notification.Types.error,
                        description: 'Failed to On / Off Socket status.',
                    });
                }
            })
            .catch(() => {
                setNotifyObj({
                    type: Notification.Types.error,
                    description: 'Failed to On / Off Socket status due to Internal Server Error.',
                });
            })
            .finally(() => {});
    };

    // Define a function to split an array into chunks of a given size
    function splitArrayIntoChunks(arr, chunkSize) {
        const chunkedArray = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            chunkedArray.push(arr.slice(i, i + chunkSize));
        }
        return chunkedArray;
    }

    const renderSocket = (record, index, isStatusCanBeChecked) => {
        const isPowerOn = record?.is_on ?? false;
        const isSocketOn = record?.socket_status ?? false;
        const isUnlabeled = record?.name.toLowerCase() === 'unlabeled';

        const SocketStatusSVG = isSocketOn ? OnlineSVG : OfflineSVG;
        const SocketAttachedSVG = isUnlabeled ? SocketSVG : AttachedSVG;

        const socketStatus = record?.socket_status;

        return (
            <div key={record?.id ?? index} className="d-flex flex-column align-items-center" style={{ gap: '0.5rem' }}>
                {socketStatus === null ? (
                    <RotateRightSVG
                        width={16}
                        height={16}
                        className={`mouse-pointer icon-color ${
                            isFetchingSocketById === index ? `clockwise-animation` : ``
                        }`}
                        onClick={() => {
                            if (isStatusCanBeChecked) {
                                fetchSmartPlugSensorStatus(bldgId, socketSensorsWithStatus, index, record?.id);
                            }
                        }}
                    />
                ) : (
                    <>
                        {isFetchingSocketById === index ? (
                            <RotateSVG
                                width={16}
                                height={16}
                                className={`mouse-pointer ${isSocketOn ? `icon-color-active` : `icon-color`} ${
                                    isFetchingSocketById === index ? `clockwise-animation` : ``
                                }`}
                            />
                        ) : (
                            <>
                                {isPowerOn ? (
                                    <SocketStatusSVG
                                        width={18}
                                        height={18}
                                        className="mouse-pointer"
                                        onClick={() => {
                                            if (!isFetchingSocketById) {
                                                handleSmartPlugSocketSwitch(
                                                    bldgId,
                                                    record?.id,
                                                    !isSocketOn,
                                                    index,
                                                    canUserToggleOnOffDevice
                                                );
                                            }
                                        }}
                                    />
                                ) : (
                                    <>
                                        <UncontrolledTooltip
                                            placement="top"
                                            target={`tooltip-${index}`}
                                            style={{
                                                maxWidth: '20vw',
                                                textAlign: 'center',
                                            }}>
                                            <div className="p-2">Device is offline</div>
                                        </UncontrolledTooltip>

                                        <CircleXmarkSVG
                                            id={`tooltip-${index}`}
                                            width={22}
                                            height={22}
                                            style={{ cursor: 'not-allowed' }}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}

                <div className={isUnlabeled ? '' : 'attached-device-socket'}>
                    <SocketAttachedSVG className={isUnlabeled ? '' : 'm-2'} />
                </div>
            </div>
        );
    };

    useEffect(() => {
        fetchSmartPlugDeviceStatus(bldgId, equipmentObj?.device_id);
        fetchDeviceSensorsList(equipmentObj?.device_id);
    }, [bldgId, equipmentObj]);

    return (
        <React.Fragment>
            <div className="smartplug-socket">
                <div className={`socket-container-style ${customClassName}`}>
                    {isFetchingSensors ? (
                        <div
                            className="w-100 d-flex align-items-center justify-content-center"
                            style={{ height: '8.5vh', color: colorPalette.primaryIndigo400 }}>
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            {splitArrayIntoChunks(socketSensorsWithStatus, 6).map((chunk, index) => (
                                <div
                                    key={index}
                                    className="d-flex justify-content-between"
                                    style={{ marginTop: index === 0 ? '0rem' : '4rem' }}>
                                    {chunk.map((socket, innerIndex) => (
                                        <div key={innerIndex}>
                                            {renderSocket(socket, innerIndex, isStatusCanBeChecked)}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>

            <hr className="m-0" />

            <div className="d-flex align-items-center justify-content-between smartplug-header">
                <Typography.Header size={Typography.Sizes.sm}>Smart Plug</Typography.Header>
                <Button label="View Device" size={Button.Sizes.md} type={Button.Type.secondaryGrey} />
            </div>

            <hr className="m-0" />

            <div className="smartplug-body">
                <div className="d-flex align-items-center justify-content-between" style={{ gap: '0.5rem' }}>
                    {isFetchingDeviceStatus ? (
                        <Skeleton
                            baseColor={colorPalette.primaryGray150}
                            highlightColor={colorPalette.baseBackground}
                            count={1}
                            borderRadius={8}
                            height={25}
                            width={100}
                        />
                    ) : (
                        <div
                            className={`d-flex align-items-center justify-content-center device-status-container device-${
                                deviceStatus ? `online` : `offline`
                            }`}>
                            <DeviceStatusSVG className="device-svg-style" />
                            <Typography.Body size={Typography.Sizes.md} style={{ color: 'white' }}>
                                {deviceStatus ? `Online` : `Offline`}
                            </Typography.Body>
                        </div>
                    )}

                    <Button
                        label="Socket Status"
                        size={Button.Sizes.md}
                        type={Button.Type.secondaryGrey}
                        style={{ height: '1.75rem', opacity: isStatusCanBeChecked ? '1' : '0.5' }}
                        icon={
                            <RotateRightSVG width={16} height={16} className={`icon-color ${socketStatusClassName}`} />
                        }
                        onClick={() => {
                            if (isStatusCanBeChecked) {
                                fetchSmartPlugSensorStatus(bldgId, socketSensorsWithStatus, 'all');
                            }
                        }}
                    />
                </div>

                <Brick sizeInRem={1.5} />

                <div className="d-flex justify-content-between" style={{ width: '60%' }}>
                    <div>
                        <div>
                            <Typography.Subheader size={Typography.Sizes.sm}>{`Identifier`}</Typography.Subheader>
                            <Brick sizeInRem={0.25} />
                            <Typography.Subheader size={Typography.Sizes.md}>
                                {equipmentObj?.device_mac ?? '-'}
                            </Typography.Subheader>
                        </div>

                        <Brick sizeInRem={1.25} />

                        <div>
                            <Typography.Subheader size={Typography.Sizes.sm}>{`Installed at`}</Typography.Subheader>
                            <Brick sizeInRem={0.25} />
                            <Typography.Subheader size={Typography.Sizes.md}>
                                {equipmentObj?.location && equipmentObj?.location !== '' ? equipmentObj?.location : '-'}
                            </Typography.Subheader>
                        </div>
                    </div>

                    <div>
                        <div>
                            <Typography.Subheader size={Typography.Sizes.sm}>{`Device Type`}</Typography.Subheader>
                            <Brick sizeInRem={0.25} />
                            <Typography.Subheader size={Typography.Sizes.md}>
                                {equipmentObj?.device_model ?? '-'}
                            </Typography.Subheader>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SmartPlugDevice;

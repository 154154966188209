import React from 'react';

import { Notification } from '../../../sharedComponents/notification/Notification';

const ModalNotification = ({ notifyObj }) => {
    const { type = Notification.Types.error, description = '' } = notifyObj;

    return (
        <div className="notification-container">
            <Notification
                type={type}
                component={Notification.ComponentTypes.alert}
                description={description}
                closeAutomatically={true}
            />
        </div>
    );
};

export default ModalNotification;

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useAtom } from 'jotai';
import { Row, Col, Spinner, UncontrolledTooltip } from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';

import { UserStore } from '../../../store/UserStore';
import { DateRangeStore } from '../../../store/DateRangeStore';
import { BuildingStore } from '../../../store/BuildingStore';
import { BreadcrumbStore } from '../../../store/BreadcrumbStore';
import { ComponentStore } from '../../../store/ComponentStore';
import { updateBuildingStore } from '../../../helpers/updateBuildingStore';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import Typography from '../../../sharedComponents/typography';
import { Button } from '../../../sharedComponents/button';
import Brick from '../../../sharedComponents/brick';
import Select from '../../../sharedComponents/form/select';
import { Badge } from '../../../sharedComponents/badge';

import UpdateSocket from './UpdateSocket';
import DeviceChartModel from '../../../pages/chartModal/DeviceChartModel';

import { handleAPIRequestBody } from '../../../helpers/helpers';
import { defaultDropdownSearch } from '../../../sharedComponents/form/select/helpers';
import { buildingData, userPermissionData } from '../../../store/globalState';

import { ReactComponent as SearchSVG } from '../../../assets/icon/search.svg';
import { ReactComponent as ChartSVG } from '../../../assets/icon/chart.svg';
import { ReactComponent as PenSVG } from '../../../assets/icon/panels/pen.svg';
import { ReactComponent as AttachedSVG } from '../../../assets/icon/active-devices/attached.svg';
import { ReactComponent as SocketSVG } from '../../../assets/icon/active-devices/socket.svg';
import { ReactComponent as OnlineSVG } from '../../../assets/icon/active-devices/online.svg';
import { ReactComponent as OfflineSVG } from '../../../assets/icon/active-devices/offline.svg';
import { ReactComponent as CircleXmarkSVG } from '../../../assets/icon/circle-xmark.svg';

import { ReactComponent as RotateSVG } from '../../../assets/icon/rotate.svg';
import { ReactComponent as RotateRightSVG } from '../../../assets/icon/rotate-right.svg';

import { ReactComponent as WifiSVG } from '../../../sharedComponents/assets/icons/wifi.svg';
import { ReactComponent as WifiSlashSVG } from '../../../sharedComponents/assets/icons/wifislash.svg';

import {
    getActiveDeviceSensors,
    getSensorData,
    getSingleActiveDevice,
    fetchSocketRelayStatus,
    updateActiveDeviceService,
    fetchDeviceStatus,
    toggleSmartPlugSocket,
} from './services';
import { getLocationData } from '../passive-devices/services';

import '../../../sharedComponents/breaker/Breaker.scss';
import colorPalette from '../../../assets/scss/_colors.scss';
import '../passive-devices/styles.scss';
import './style.css';
import './styles.scss';

const SocketRelayStatus = (props) => {
    const {
        bldgId,
        socketSensorsWithStatus = [],
        deviceStatus = false,
        fetchSmartPlugSensorStatus,
        handleSmartPlugSocketSwitch,
        isFetchingSocketById = null,
        isFetchingAllSocketStatus = false,
        isFetchingSensors = false,
        isFetchingDeviceStatus = false,
        isSuperAdmin = false,
        canUserEdit = false,
    } = props;

    const DeviceStatusSVG = deviceStatus ? WifiSVG : WifiSlashSVG;
    const customClassName = socketSensorsWithStatus.length === 1 ? 'd-flex justify-content-center' : '';
    const socketStatusClassName = isFetchingAllSocketStatus ? `clockwise-animation` : ``;
    const canUserToggleOnOffDevice = isSuperAdmin || canUserEdit;

    const renderSocket = (record, index, isStatusCanBeChecked) => {
        const isPowerOn = record?.is_on ?? false;
        const isSocketOn = record?.socket_status ?? false;
        const isUnlabeled = record?.name.toLowerCase() === 'unlabeled';

        const SocketStatusSVG = isSocketOn ? OnlineSVG : OfflineSVG;
        const SocketAttachedSVG = isUnlabeled ? SocketSVG : AttachedSVG;

        const socketStatus = record?.socket_status;

        return (
            <div key={record?.id ?? index} className="d-flex flex-column align-items-center" style={{ gap: '0.5rem' }}>
                {socketStatus === null ? (
                    <RotateRightSVG
                        width={16}
                        height={16}
                        className={`mouse-pointer icon-color ${
                            isFetchingSocketById === index ? `clockwise-animation` : ``
                        }`}
                        onClick={() => {
                            if (isStatusCanBeChecked) {
                                fetchSmartPlugSensorStatus(bldgId, socketSensorsWithStatus, index, record?.id);
                            }
                        }}
                    />
                ) : (
                    <>
                        {isFetchingSocketById === index ? (
                            <RotateSVG
                                width={16}
                                height={16}
                                className={`mouse-pointer ${isSocketOn ? `icon-color-active` : `icon-color`} ${
                                    isFetchingSocketById === index ? `clockwise-animation` : ``
                                }`}
                            />
                        ) : (
                            <>
                                {isPowerOn ? (
                                    <SocketStatusSVG
                                        width={18}
                                        height={18}
                                        className="mouse-pointer"
                                        onClick={() => {
                                            if (!isFetchingSocketById) {
                                                handleSmartPlugSocketSwitch(
                                                    bldgId,
                                                    record?.id,
                                                    !isSocketOn,
                                                    index,
                                                    canUserToggleOnOffDevice
                                                );
                                            }
                                        }}
                                    />
                                ) : (
                                    <>
                                        <UncontrolledTooltip
                                            placement="top"
                                            target={`tooltip-${index}`}
                                            style={{
                                                maxWidth: '20vw',
                                                textAlign: 'center',
                                            }}>
                                            <div className="p-2">Device is offline</div>
                                        </UncontrolledTooltip>

                                        <CircleXmarkSVG
                                            id={`tooltip-${index}`}
                                            width={22}
                                            height={22}
                                            style={{ cursor: 'not-allowed' }}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}

                <div className={isUnlabeled ? '' : 'attached-device-socket'}>
                    <SocketAttachedSVG className={isUnlabeled ? '' : 'm-2'} />
                </div>
            </div>
        );
    };

    // Define a function to split an array into chunks of a given size
    function splitArrayIntoChunks(arr, chunkSize) {
        const chunkedArray = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            chunkedArray.push(arr.slice(i, i + chunkSize));
        }
        return chunkedArray;
    }

    const isStatusCanBeChecked =
        socketSensorsWithStatus.length !== 0 && !isFetchingAllSocketStatus && !isFetchingSocketById;

    return (
        <>
            <div className="d-flex align-items-center justify-content-between" style={{ gap: '0.5rem' }}>
                {isFetchingDeviceStatus ? (
                    <Skeleton
                        baseColor={colorPalette.primaryGray150}
                        highlightColor={colorPalette.baseBackground}
                        count={1}
                        borderRadius={8}
                        height={25}
                        width={100}
                    />
                ) : (
                    <div
                        className={`d-flex align-items-center justify-content-center device-status-container device-${
                            deviceStatus ? `online` : `offline`
                        }`}>
                        <DeviceStatusSVG className="device-svg-style" />
                        <Typography.Body size={Typography.Sizes.md} style={{ color: 'white' }}>
                            {deviceStatus ? `Online` : `Offline`}
                        </Typography.Body>
                    </div>
                )}

                <Button
                    label="Socket Status"
                    size={Button.Sizes.md}
                    type={Button.Type.secondaryGrey}
                    style={{ height: '1.75rem', opacity: isStatusCanBeChecked ? '1' : '0.5' }}
                    icon={<RotateRightSVG width={16} height={16} className={`icon-color ${socketStatusClassName}`} />}
                    onClick={() => {
                        if (isStatusCanBeChecked) {
                            fetchSmartPlugSensorStatus(bldgId, socketSensorsWithStatus, 'all');
                        }
                    }}
                />
            </div>

            <Brick sizeInRem={0.75} />

            <div className={`socket-container-style ${customClassName}`}>
                {isFetchingSensors ? (
                    <div
                        className="w-100 d-flex align-items-center justify-content-center"
                        style={{ height: '8.5vh', color: colorPalette.primaryIndigo400 }}>
                        <Spinner />
                    </div>
                ) : (
                    <>
                        {splitArrayIntoChunks(socketSensorsWithStatus, 6).map((chunk, index) => (
                            <div
                                key={index}
                                className="d-flex justify-content-between"
                                style={{ marginTop: index === 0 ? '0rem' : '4rem' }}>
                                {chunk.map((socket, innerIndex) => (
                                    <div key={innerIndex}>{renderSocket(socket, innerIndex, isStatusCanBeChecked)}</div>
                                ))}
                            </div>
                        ))}
                    </>
                )}
            </div>
        </>
    );
};

const IndividualActiveDevice = () => {
    const history = useHistory();
    const [userPermission] = useAtom(userPermissionData);

    const isUserAdmin = userPermission?.is_admin ?? false;
    const isSuperUser = userPermission?.is_superuser ?? false;
    const isSuperAdmin = isUserAdmin || isSuperUser;
    const canUserEdit = userPermission?.permissions?.permissions?.advanced_active_device_permission?.edit ?? false;

    const startDate = DateRangeStore.useState((s) => s.startDate);
    const endDate = DateRangeStore.useState((s) => s.endDate);
    const startTime = DateRangeStore.useState((s) => s.startTime);
    const endTime = DateRangeStore.useState((s) => s.endTime);
    const daysCount = DateRangeStore.useState((s) => +s.daysCount);

    // Chart states
    const [showChart, setShowChart] = useState(false);
    const handleChartClose = () => setShowChart(false);

    const { deviceId } = useParams();
    const [sensorId, setSensorId] = useState('');

    const { bldgId } = useParams();
    const [buildingListData] = useAtom(buildingData);
    const timeZone = BuildingStore.useState((s) => s.BldgTimeZone);
    const [locationData, setLocationData] = useState([]);
    const [locationError, setLocationError] = useState(null);
    const [isLocationFetched, setIsLocationFetched] = useState(true);

    const [activeData, setActiveData] = useState({});
    const [isFetchingSmartPlugData, setFetchingSmartPlugData] = useState(false);
    const [activeLocationId, setActiveLocationId] = useState('');

    const [socketSensorsList, setSocketSensorsList] = useState([]);
    const [isFetchingSensors, setFetchingSensorsList] = useState(false);

    const [socketStatusList, setSocketStatusList] = useState([]);
    const [isFetchingSocketById, setFetchingSocketById] = useState(null); // Fetch by Socket ID
    const [isFetchingAllSocketStatus, setFetchingAllSocketStatus] = useState(false);

    const socketSensorsWithStatus = socketSensorsList.map((el) => {
        const socketStatusObj = socketStatusList.find((obj) => obj?.socket_id === el?.socket_id);
        return {
            ...el,
            socket_status: socketStatusObj?.socket_status ?? null,
            is_on: socketStatusObj?.is_on ?? false,
        };
    });

    const [deviceStatus, setDeviceStatus] = useState(false);
    const [isFetchingDeviceStatus, setFetchingDeviceStatus] = useState(false);

    const [isSensorChartLoading, setIsSensorChartLoading] = useState(true);
    const [sensorData, setSensorData] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);
    const [searchSocket, setSearchSocket] = useState('');

    // Equipment states
    const [isSocketModalOpen, setSocketModalState] = useState(false);
    const closeSocketModal = () => setSocketModalState(false);
    const openSocketModal = () => setSocketModalState(true);

    const [selectedSocketObj, setSelectedSocketObj] = useState({});

    const handleSocketChange = (e) => {
        setSearchSocket(e.target.value);
    };

    const filtered = !searchSocket
        ? socketSensorsWithStatus
        : socketSensorsWithStatus.filter((sensor) => {
              return (
                  sensor.equipment_type_name.toLowerCase().includes(searchSocket.toLowerCase()) ||
                  sensor.equipment.toLowerCase().includes(searchSocket.toLowerCase())
              );
          });

    const [seriesData, setSeriesData] = useState([]);
    const [deviceData, setDeviceData] = useState([]);

    const CONVERSION_ALLOWED_UNITS = ['power'];

    const UNIT_DIVIDER = 1000;

    const [metric, setMetric] = useState([
        { value: 'energy', label: 'Energy Consumed (Wh)', unit: 'Wh', Consumption: 'Energy Consumption' },
        {
            value: 'totalconsumedenergy',
            label: 'Total Consumed Energy (Wh)',
            unit: 'Wh',
            Consumption: 'Total Consumed Energy',
        },
        { value: 'mV', label: 'Voltage (mV)', unit: 'mV', Consumption: 'Voltage' },
        { value: 'mAh', label: 'Current (mA)', unit: 'mA', Consumption: 'Current' },
        { value: 'power', label: 'Real Power (W)', unit: 'W', Consumption: 'Real Power' },
    ]);

    const [selectedConsumption, setConsumption] = useState(metric[0].value);
    const [selectedUnit, setSelectedUnit] = useState(metric[0].unit);
    const [selectedConsumptionLabel, setSelectedConsumptionLabel] = useState(metric[0].Consumption);

    const getRequiredConsumptionLabel = (value) => {
        let label = '';

        metric.map((m) => {
            if (m.value === value) {
                label = m.label;
            }

            return m;
        });

        return label;
    };

    const handleChartShow = (id) => {
        setSensorId(id);
        let obj = socketSensorsWithStatus.find((o) => o.id === id);
        setSensorData(obj);
        fetchSensorChartData(id);
        setShowChart(true);
    };

    const fetchLocationData = async () => {
        setIsLocationFetched(true);
        await getLocationData(`/${bldgId}`)
            .then((res) => {
                let response = res?.data;
                response.sort((a, b) => {
                    return a.location_name.localeCompare(b.location_name);
                });
                let locationList = [];
                response.forEach((el) => {
                    let obj = {
                        label: el?.location_name,
                        value: el?.location_id,
                    };
                    locationList.push(obj);
                });
                setLocationData(locationList);
                setIsLocationFetched(false);
            })
            .catch(() => {
                setIsLocationFetched(false);
            });
    };

    const fetchSmartPlugDeviceData = async () => {
        setFetchingSmartPlugData(true);

        const params = `?device_id=${deviceId}&page_size=100&page_no=1&building_id=${bldgId}`;

        await getSingleActiveDevice(params)
            .then((res) => {
                const { data = [] } = res?.data;
                if (data[0]) {
                    const deviceObj = data[0];
                    setActiveData(deviceObj ?? {});
                    if (deviceObj.location_id) setActiveLocationId(deviceObj?.location_id);
                    localStorage.setItem('identifier', deviceObj?.identifier);
                }
            })
            .catch(() => {})
            .finally(() => {
                setFetchingSmartPlugData(false);
            });
    };

    const fetchSmartPlugDeviceStatus = async (bldg_id, device_id) => {
        if (!bldg_id || !device_id) return;

        setFetchingDeviceStatus(true);
        setDeviceStatus(false);

        const params = `?building_id=${bldg_id}&device_ids=${device_id}`;

        await fetchDeviceStatus(params)
            .then((res) => {
                const { success = false, data = [] } = res?.data;
                if (success && data[0]) setDeviceStatus(data[0]?.is_on ?? false);
            })
            .catch(() => {})
            .finally(() => {
                setFetchingDeviceStatus(false);
            });
    };

    const fetchSmartPlugSensorStatus = async (bldg_id, sensors_list = [], socketID = 'all', sensorId) => {
        if (!bldg_id) return;
        if (socketID !== 'all' && !sensorId) return;
        if (socketID === 'all' && sensors_list.length === 0) return;

        if (socketID === 'all') {
            setFetchingAllSocketStatus(true);
            setSocketStatusList([]);
        } else {
            setFetchingSocketById(socketID);
        }

        const sensorIds = encodeURIComponent(sensors_list.map(({ id }) => id).join('+'));
        const params = `?building_id=${bldg_id}&sensor_ids=${socketID === 'all' ? sensorIds : sensorId}`;

        await fetchSocketRelayStatus(params)
            .then((res) => {
                const { success = false, data = [] } = res?.data;

                if (success && data.length !== 0) {
                    if (socketID === 'all') {
                        setSocketStatusList(data);

                        UserStore.update((s) => {
                            s.showNotification = true;
                            s.notificationMessage = 'Socket status fetched successfully.';
                            s.notificationType = 'success';
                        });
                    } else {
                        if (socketStatusList.length === 0) {
                            setSocketStatusList(data);
                        } else {
                            let clonedList = _.cloneDeep(socketStatusList);
                            let socketStatusModified = false;

                            // Check if any element in 'data' matches an element in 'clonedList'
                            data.forEach((socket) => {
                                const existingSocket = clonedList.find((el) => el?.socket_id === socket?.socket_id);
                                if (existingSocket) {
                                    socketStatusModified = true;
                                    existingSocket.socket_status = socket.socket_status ?? false;
                                }
                            });

                            // If no match found, add the first element of 'data' to 'clonedList'
                            if (!socketStatusModified && data.length > 0) {
                                clonedList.push(data[0]);
                            }

                            setSocketStatusList(clonedList);
                        }
                    }
                } else {
                    UserStore.update((s) => {
                        s.showNotification = true;
                        s.notificationMessage = 'Unable to fetch Socket status.';
                        s.notificationType = 'error';
                    });
                    setSocketStatusList([]);
                }
            })
            .catch(() => {
                UserStore.update((s) => {
                    s.showNotification = true;
                    s.notificationMessage = 'Failed to fetch Socket status due to Internal Server Error';
                    s.notificationType = 'error';
                });
                setSocketStatusList([]);
            })
            .finally(() => {
                if (socketID === 'all') {
                    setFetchingAllSocketStatus(false);
                } else {
                    setFetchingSocketById(null);
                }
            });
    };

    const handleSmartPlugSocketSwitch = async (
        bldg_id,
        sensorId,
        toggleOn = false,
        index,
        canUserToggleOnOffDevice
    ) => {
        if (!bldg_id || !sensorId) return;

        if (!canUserToggleOnOffDevice) {
            UserStore.update((s) => {
                s.showNotification = true;
                s.notificationMessage = `You don't have permission to turn the socket on or off.`;
                s.notificationType = 'error';
            });
            return;
        }

        setFetchingSocketById(index);

        const params = `?building_id=${bldg_id}&toggle=${toggleOn ? 'on' : 'off'}`;
        const payload = {
            sensor_ids: [sensorId],
        };

        await toggleSmartPlugSocket(params, payload)
            .then((res) => {
                const { success = false, data = [], message } = res?.data;
                if (success) {
                    fetchSmartPlugSensorStatus(bldg_id, [], index, sensorId);
                    UserStore.update((s) => {
                        s.showNotification = true;
                        s.notificationMessage = 'Socket Toggle On / Off successfully.';
                        s.notificationType = 'success';
                    });
                } else {
                    UserStore.update((s) => {
                        s.showNotification = true;
                        s.notificationMessage = 'Failed to On / Off Socket status.';
                        s.notificationType = 'error';
                    });
                }
            })
            .catch(() => {
                UserStore.update((s) => {
                    s.showNotification = true;
                    s.notificationMessage = 'Failed to On / Off Socket status due to Internal Server Error.';
                    s.notificationType = 'error';
                });
            })
            .finally(() => {});
    };

    const updateBreadcrumbStore = () => {
        BreadcrumbStore.update((bs) => {
            let newList = [
                {
                    label: 'Smart Plugs',
                    path: `/settings/smart-plugs/${bldgId}`,
                    active: false,
                },
            ];
            bs.items = newList;
        });
        ComponentStore.update((s) => {
            s.parent = 'building-settings';
        });
    };

    const fetchSmartPlugSocketsList = async () => {
        setFetchingSensorsList(true);
        setSocketSensorsList([]);

        const params = `?device_id=${deviceId}`;

        await getActiveDeviceSensors(params)
            .then(({ data = [] }) => {
                setSocketSensorsList(data);
            })
            .catch(() => {})
            .finally(() => {
                setFetchingSensorsList(false);
            });
    };

    const fetchSensorChartData = async (id) => {
        setIsSensorChartLoading(true);
        const params = `?sensor_id=${id === sensorId ? sensorId : id}&consumption=energy&building_id=${bldgId}`;
        await getSensorData(params, handleAPIRequestBody(startDate, endDate, timeZone, startTime, endTime))
            .then((res) => {
                setDeviceData([]);
                setSeriesData([]);
                let response = res.data;

                let data = response;

                let exploreData = [];

                let NulledData = [];
                data.map((ele) => {
                    if (ele?.consumption === '') {
                        NulledData.push({ x: new Date(ele?.time_stamp).getTime(), y: null });
                    } else {
                        if (CONVERSION_ALLOWED_UNITS.indexOf(selectedConsumption) > -1) {
                            NulledData.push({
                                x: new Date(ele.time_stamp).getTime(),
                                y: ele.consumption / UNIT_DIVIDER,
                            });
                        } else {
                            NulledData.push({ x: new Date(ele.time_stamp).getTime(), y: ele.consumption });
                        }
                    }
                });
                let recordToInsert = {
                    data: NulledData,
                    name: getRequiredConsumptionLabel(selectedConsumption),
                };
                setDeviceData([recordToInsert]);
                setIsSensorChartLoading(false);
            })
            .catch(() => {
                setIsSensorChartLoading(false);
            });
    };

    const redirectToActivePage = () => {
        history.push({ pathname: `/settings/smart-plugs/${bldgId}` });
    };

    const updateActiveDevice = async () => {
        if (activeLocationId === activeData?.location_id) {
            setLocationError({
                text: 'Please update Location.',
            });
            return;
        }

        if (!activeData?.equipments_id) return;
        setIsProcessing(true);
        const params = `?device_id=${activeData?.equipments_id}`;
        const payload = { location_id: activeLocationId };
        await updateActiveDeviceService(params, payload)
            .then((res) => {
                const response = res?.data;
                if (response?.success) {
                    UserStore.update((s) => {
                        s.showNotification = true;
                        s.notificationMessage = response?.message;
                        s.notificationType = 'success';
                    });
                } else {
                    UserStore.update((s) => {
                        s.showNotification = true;
                        s.notificationMessage = response?.message
                            ? response?.message
                            : res
                            ? 'Unable to update Smart Plug.'
                            : 'Unable to update Smart Plug due to Internal Server Error!.';
                        s.notificationType = 'error';
                    });
                }
                setIsProcessing(false);
                setLocationError(null);
                fetchSmartPlugDeviceData();
            })
            .catch(() => {
                setIsProcessing(false);
                setLocationError(null);
            });
    };

    useEffect(() => {
        if (!isSocketModalOpen) setSelectedSocketObj({});
    }, [isSocketModalOpen]);

    useEffect(() => {
        fetchSmartPlugDeviceData(); // Fetch selected device configuration
        fetchSmartPlugSocketsList(); // Fetch selected device sockets list
        fetchSmartPlugDeviceStatus(bldgId, deviceId); // Fetch selected device status
        fetchLocationData();
    }, [bldgId, deviceId]);

    useEffect(() => {
        if (!showChart) setConsumption('energy');
    }, [showChart]);

    useEffect(() => {
        BreadcrumbStore.update((bs) => {
            let newList = [
                {
                    label: 'Smart Plugs',
                    path: `/settings/smart-plugs/${bldgId}`,
                    active: false,
                },
                {
                    label: activeData?.identifier,
                    path: '/settings/smart-plugs/single',
                    active: true,
                },
            ];
            bs.items = newList;
        });
    }, [activeData]);

    useEffect(() => {
        if (bldgId && buildingListData.length !== 0) {
            const bldgObj = buildingListData.find((el) => el?.building_id === bldgId);
            if (bldgObj?.building_id)
                updateBuildingStore(
                    bldgObj?.building_id,
                    bldgObj?.building_name,
                    bldgObj?.timezone,
                    bldgObj?.plug_only
                );
        }
    }, [buildingListData, bldgId]);

    useEffect(() => {
        window.scrollTo(0, 0);
        updateBreadcrumbStore();
    }, []);

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <div className="passive-header-wrapper d-flex justify-content-between">
                        <div className="d-flex flex-column">
                            <Typography.Subheader size={Typography.Sizes.sm} className="font-weight-bold">
                                Smart Plug
                            </Typography.Subheader>
                            <div className="d-flex">
                                <Typography.Header size={Typography.Sizes.md} className="mr-2">
                                    {activeData?.model === 'KP115' && 'Smart Mini Plug'}
                                    {activeData?.model === 'HS300' && 'Power Strip'}
                                </Typography.Header>
                                <Typography.Subheader
                                    size={Typography.Sizes.md}
                                    className="d-flex align-items-center mt-1">
                                    {activeData?.identifier}
                                </Typography.Subheader>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div>
                                <Button
                                    label="Cancel"
                                    size={Button.Sizes.md}
                                    type={Button.Type.secondaryGrey}
                                    onClick={redirectToActivePage}
                                />
                            </div>
                            <div>
                                {isSuperAdmin || canUserEdit ? (
                                    <Button
                                        label={isProcessing ? 'Saving' : 'Save'}
                                        size={Button.Sizes.md}
                                        type={Button.Type.primary}
                                        onClick={updateActiveDevice}
                                        className="ml-2"
                                        disabled={isProcessing || activeLocationId === activeData?.location_id}
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row className="passive-container">
                <Col lg={4}>
                    <Typography.Subheader size={Typography.Sizes.md}>Device Details</Typography.Subheader>

                    <Brick sizeInRem={1} />

                    <div>
                        <Typography.Subheader size={Typography.Sizes.sm}>Installed Location</Typography.Subheader>
                        <Brick sizeInRem={0.25} />
                        {isLocationFetched || isProcessing ? (
                            <Skeleton
                                baseColor={colorPalette.primaryGray150}
                                highlightColor={colorPalette.baseBackground}
                                count={1}
                                height={35}
                            />
                        ) : (
                            <Select
                                placeholder="Select Location"
                                options={locationData}
                                currentValue={locationData.filter((option) => option.value === activeLocationId)}
                                onChange={(e) => {
                                    setActiveLocationId(e.value);
                                    setLocationError(null);
                                }}
                                isSearchable={true}
                                customSearchCallback={({ data, query }) => defaultDropdownSearch(data, query?.value)}
                                disabled={!(isSuperAdmin || canUserEdit)}
                                error={locationError}
                            />
                        )}
                        <Brick sizeInRem={0.25} />
                        {!locationError && (
                            <Typography.Body size={Typography.Sizes.sm}>
                                Location this device is installed.
                            </Typography.Body>
                        )}
                    </div>

                    <Brick sizeInRem={1.5} />

                    <div className="smartplug-device-container w-100">
                        {isFetchingSmartPlugData ? (
                            <div
                                className="w-100 d-flex align-items-center justify-content-center"
                                style={{ height: '12vh', color: colorPalette.primaryIndigo400 }}>
                                <Spinner />
                            </div>
                        ) : (
                            <div className="d-flex justify-content-between w-75">
                                <div>
                                    <div>
                                        <Typography.Subheader size={Typography.Sizes.sm}>
                                            Identifier
                                        </Typography.Subheader>
                                        <Brick sizeInRem={0.25} />
                                        <Typography.Subheader size={Typography.Sizes.md}>
                                            {activeData?.identifier}
                                        </Typography.Subheader>
                                    </div>

                                    <Brick sizeInRem={1} />

                                    <div>
                                        <Typography.Subheader size={Typography.Sizes.sm}>
                                            Firmware Version
                                        </Typography.Subheader>
                                        <Brick sizeInRem={0.25} />
                                        <Typography.Subheader size={Typography.Sizes.md}>v1.2</Typography.Subheader>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <Typography.Subheader size={Typography.Sizes.sm}>
                                            Device Model
                                        </Typography.Subheader>
                                        <Brick sizeInRem={0.25} />
                                        <Typography.Subheader size={Typography.Sizes.md}>
                                            {activeData?.model}
                                        </Typography.Subheader>
                                    </div>

                                    <Brick sizeInRem={1} />

                                    <div>
                                        <Typography.Subheader size={Typography.Sizes.sm}>
                                            Device Version
                                        </Typography.Subheader>
                                        <Brick sizeInRem={0.25} />
                                        <Typography.Subheader size={Typography.Sizes.md}>v2</Typography.Subheader>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <Brick sizeInRem={1.5} />

                    <SocketRelayStatus
                        bldgId={bldgId}
                        socketSensorsWithStatus={socketSensorsWithStatus}
                        deviceStatus={deviceStatus}
                        fetchSmartPlugSensorStatus={fetchSmartPlugSensorStatus}
                        handleSmartPlugSocketSwitch={handleSmartPlugSocketSwitch}
                        isFetchingSocketById={isFetchingSocketById}
                        isFetchingAllSocketStatus={isFetchingAllSocketStatus}
                        isFetchingSensors={isFetchingSensors}
                        isFetchingDeviceStatus={isFetchingDeviceStatus}
                        isSuperAdmin={isSuperAdmin}
                        canUserEdit={canUserEdit}
                    />
                </Col>

                <Col lg={8}>
                    <Typography.Subheader
                        size={
                            Typography.Sizes.md
                        }>{`Sockets (${socketSensorsWithStatus.length})`}</Typography.Subheader>
                    <Brick sizeInRem={0.5} />
                    <div className="active-sensor-header">
                        <div className="search-container mr-2">
                            <SearchSVG className="mb-1" />
                            <input
                                className="search-box ml-2"
                                type="search"
                                name="search"
                                placeholder="Search"
                                value={searchSocket}
                                onChange={handleSocketChange}
                            />
                        </div>
                    </div>

                    <Brick sizeInRem={0.25} />

                    {isFetchingSensors ? (
                        <div>
                            <Skeleton
                                baseColor={colorPalette.primaryGray150}
                                highlightColor={colorPalette.baseBackground}
                                count={8}
                                height={40}
                            />
                        </div>
                    ) : (
                        <>
                            {filtered.map((record, index) => {
                                return (
                                    <>
                                        <Brick sizeInRem={0.75} />
                                        <div
                                            className={`d-flex justify-content-between align-items-center sensor-container ${
                                                (record?.equipment_id === '' && record?.breaker_id === '') ||
                                                record?.name.toLowerCase() === 'unlabeled'
                                                    ? 'sensor-unattach'
                                                    : ''
                                            }`}>
                                            <div className="d-flex align-items-center mouse-pointer">
                                                <Typography.Subheader
                                                    size={Typography.Sizes.md}
                                                    className="sensor-index mr-4">
                                                    {index + 1}
                                                </Typography.Subheader>
                                                <Typography.Subheader
                                                    size={Typography.Sizes.md}
                                                    className={`mr-2 ${
                                                        (record?.equipment_type_name === '' &&
                                                            record?.equipment === '') ||
                                                        record?.name.toLowerCase() === 'unlabeled'
                                                            ? 'sensor-index'
                                                            : ''
                                                    }`}>
                                                    {record?.equipment_type_name}
                                                </Typography.Subheader>
                                                {record?.equipment_id && (
                                                    <Badge text={record?.equipment} className="sensor-badge-style" />
                                                )}
                                            </div>
                                            <div className="d-flex">
                                                <Button
                                                    className="breaker-action-btn"
                                                    onClick={() => handleChartShow(record?.id)}
                                                    type={Button.Type.secondaryGrey}
                                                    label=""
                                                    icon={<ChartSVG width={16} />}
                                                />

                                                <Button
                                                    className="breaker-action-btn ml-2"
                                                    onClick={() => {
                                                        setSelectedSocketObj(record);
                                                        openSocketModal();
                                                    }}
                                                    type={Button.Type.secondaryGrey}
                                                    label=""
                                                    icon={<PenSVG width={15} />}
                                                />
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </>
                    )}
                </Col>
            </Row>

            <DeviceChartModel
                showChart={showChart}
                handleChartClose={handleChartClose}
                sensorData={sensorData}
                seriesData={seriesData}
                setSeriesData={setSeriesData}
                deviceData={deviceData}
                setDeviceData={setDeviceData}
                CONVERSION_ALLOWED_UNITS={CONVERSION_ALLOWED_UNITS}
                UNIT_DIVIDER={UNIT_DIVIDER}
                metric={metric}
                setMetric={setMetric}
                selectedConsumption={selectedConsumption}
                setConsumption={setConsumption}
                selectedUnit={selectedUnit}
                setSelectedUnit={setSelectedUnit}
                selectedConsumptionLabel={selectedConsumptionLabel}
                setSelectedConsumptionLabel={setSelectedConsumptionLabel}
                getRequiredConsumptionLabel={getRequiredConsumptionLabel}
                isSensorChartLoading={isSensorChartLoading}
                setIsSensorChartLoading={setIsSensorChartLoading}
                timeZone={timeZone}
                daysCount={daysCount}
                deviceType="active"
            />

            <UpdateSocket
                isSocketModalOpen={isSocketModalOpen}
                closeSocketModal={closeSocketModal}
                bldgId={bldgId}
                selectedSocketObj={selectedSocketObj}
                fetchActiveSensorsList={fetchSmartPlugSocketsList}
            />
        </React.Fragment>
    );
};

export default IndividualActiveDevice;

import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

import Typography from '../../../sharedComponents/typography';
import Brick from '../../../sharedComponents/brick';
import { Button } from '../../../sharedComponents/button';

import { UserStore } from '../../../store/UserStore';
import { deleteSmartPlugDevice } from './services';

const DeleteActiveDevice = ({ isDeleteModalOpen, closeDeleteModal, selectedActiveDevice, nextActionAfterDeletion }) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [deviceId, setDeviceId] = useState('');

    const handleSmartPlugDelete = async () => {
        setIsProcessing(true);
        const params = `?device_ids=${deviceId}`;

        await deleteSmartPlugDevice(params)
            .then((res) => {
                closeDeleteModal();
                setDeviceId('');
                nextActionAfterDeletion();
                UserStore.update((s) => {
                    s.showNotification = true;
                    s.notificationMessage = 'Smart Plug deleted successfully.';
                    s.notificationType = 'success';
                });
            })
            .catch(() => {
                UserStore.update((s) => {
                    s.showNotification = true;
                    s.notificationMessage = 'Unable to delete Smart Plug due to Internal Server Error.';
                    s.notificationType = 'error';
                });
            })
            .finally(() => {
                setIsProcessing(false);
            });
    };

    useEffect(() => {
        if (isDeleteModalOpen) setDeviceId(selectedActiveDevice?.equipments_id);
    }, [isDeleteModalOpen]);

    return (
        <Modal show={isDeleteModalOpen} onHide={closeDeleteModal} centered backdrop="static" keyboard={false}>
            <Modal.Body className="p-4">
                <Typography.Header size={Typography.Sizes.lg}>Delete Smart Plug</Typography.Header>
                <Brick sizeInRem={1.5} />
                <Typography.Body size={Typography.Sizes.lg}>
                    Are you sure you want to delete the selected Smart Plug?
                </Typography.Body>
            </Modal.Body>
            <Modal.Footer className="pb-4 pr-4">
                <Button
                    label="Cancel"
                    size={Button.Sizes.lg}
                    type={Button.Type.secondaryGrey}
                    onClick={closeDeleteModal}
                />
                <Button
                    label={isProcessing ? 'Deleting' : 'Delete'}
                    size={Button.Sizes.lg}
                    type={Button.Type.primaryDistructive}
                    disabled={isProcessing}
                    onClick={handleSmartPlugDelete}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteActiveDevice;

import axiosInstance from '../../../../services/axiosInstance';
import {
    get_kasa_devices,
    get_kasa_account,
    kasaLinkAccount,
    kasaUnLinkAccount,
    insert_kasa_devices,
    addToSystem,
} from '../../../../services/Network';

export function fetchKasaDevices({ bldgId, deviceSearch, statusType, pageNo, pageSize, sortBy, orderedBy, payload }) {
    let params = `?building_id=${bldgId}`;

    if (deviceSearch) params += `&device_search=${encodeURIComponent(deviceSearch)}`;
    if (statusType) params += `&status_type=${statusType}`;

    if (pageNo && pageSize) params += `&page_no=${pageNo}&page_size=${pageSize}`;

    if (sortBy) params += `&sort_by=${sortBy}`;
    if (orderedBy) params += `&ordered_by=${orderedBy}`;

    return axiosInstance.post(`${get_kasa_devices}${params}`, payload).then((res) => res);
}

export function fetchKasaAccounts(pageNo, pageSize, bldgId, search, getParams) {
    const searchData = encodeURIComponent(search);
    let params = `?building_id=${bldgId}&account_search=${searchData}&page_size=${pageSize}&page_no=${pageNo}`;
    if (getParams.order_by && getParams.sort_by) {
        params += `&ordered_by=${getParams.order_by}&sort_by=${getParams.sort_by}`;
    }
    return axiosInstance.get(`${get_kasa_account}${params}`).then((res) => res);
}

export function getKasaLinkAccounts(payload) {
    return axiosInstance.post(`${kasaLinkAccount}`, payload).then((res) => res);
}

export function getKasaUnLinkAccounts(payload) {
    return axiosInstance.post(`${kasaUnLinkAccount}`, payload).then((res) => res);
}

export function getInsertKasaDevices(payload) {
    return axiosInstance.post(`${insert_kasa_devices}`, payload).then((res) => res);
}

export function insertToSystem(payload) {
    return axiosInstance.post(`${addToSystem}`, payload).then((res) => res);
}

export const equipmentTypesList = [
    {
        label: 'Custom',
        value: 'Custom',
    },
    {
        label: 'System',
        value: 'System',
    },
];

import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import { Button } from '../../../../../sharedComponents/button';
import Typography from '../../../../../sharedComponents/typography';

import { ReactComponent as CircleXmarkSVG } from '../../../../../assets/icon/circle-xmark.svg';
import { ReactComponent as ActiveSVG } from '../../../../../assets/icon/circle-check.svg';
import { ReactComponent as PendingSVG } from '../../../../../assets/icon/clock.svg';

import '../../styles.scss';

export const renderStatus = (row) => {
    const { status, is_super_user = false, is_error = false, error = {} } = row;

    let classes = `${status.toLowerCase()}-btn`;
    if (is_error) classes = `failed-btn`;

    const TooltipRow = ({ label, value, className = '' }) => (
        <div className={`d-flex ${className}`} style={{ gap: '0.5rem' }}>
            <div>{label}</div>
            <div>{value}</div>
        </div>
    );

    return (
        <>
            {is_error && (
                <UncontrolledTooltip
                    placement="top"
                    target={`tooltip-${row?.index}`}
                    style={{ maxWidth: is_super_user ? 'max-content' : '20vw', textAlign: 'left' }}>
                    {is_super_user && (
                        <div className="p-2" style={{ columnGap: '0.5rem' }}>
                            <TooltipRow label="Configured Building ID:" value={error?.configured_building_id ?? '-'} />
                            <TooltipRow
                                label="Configured Building Name:"
                                value={error?.configured_building_name ?? '-'}
                                className="mt-2"
                            />
                            <TooltipRow
                                label="Installed Account ID:"
                                value={error?.current_installed_account_id ?? '-'}
                                className="mt-2"
                            />
                            <TooltipRow
                                label="Installed Account Name:"
                                value={error?.current_installed_account_name ?? '-'}
                                className="mt-2"
                            />
                        </div>
                    )}
                    {!is_super_user && (
                        <div className="p-2">
                            Duplicate Device ID found. Please check your buildings for existing smart plugs using this
                            Device ID or contact Sapient support for help.
                        </div>
                    )}
                </UncontrolledTooltip>
            )}

            <Button
                id={`${is_error ? `tooltip-` : ``}${row?.index}`}
                label={is_error ? `Unavailable` : status}
                size={Button.Sizes.lg}
                type={Button.Type.secondary}
                icon={
                    (status === 'Completed' && <ActiveSVG />) ||
                    ((status === 'Failed' || is_error) && <CircleXmarkSVG />) ||
                    (status === 'Found' && <PendingSVG />)
                }
                iconAlignment={Button.IconAlignment.left}
                className={`device-status-container mouse-pointer ${classes}`}
                disabled
            />
        </>
    );
};

export const renderDeviceMac = (row) => {
    return <Typography.Body size={Typography.Sizes.md}>{row?.device_mac ?? '-'}</Typography.Body>;
};

export const renderVendor = (row) => {
    return <Typography.Body size={Typography.Sizes.md}>{row?.vendor ?? '-'}</Typography.Body>;
};

export const renderModel = (row) => {
    return <Typography.Body size={Typography.Sizes.md}>{row?.model ?? '-'}</Typography.Body>;
};

export const renderKasaAccount = (row) => {
    return <div className="typography-wrapper link mouse-pointer">{row?.kasa_account ?? '-'}</div>;
};

import React, { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import { Row, Col, Alert } from 'reactstrap';

import { UserStore } from '../../../../store/UserStore';
import { BreadcrumbStore } from '../../../../store/BreadcrumbStore';
import { ComponentStore } from '../../../../store/ComponentStore';
import { buildingData, userPermissionData } from '../../../../store/globalState';
import { updateBuildingStore } from '../../../../helpers/updateBuildingStore';

import { ReactComponent as CircleCheckSVG } from '../../../../assets/icon/circle-check.svg';
import { ReactComponent as LinkSVG } from '../../../../assets/icon/link.svg';
import { ReactComponent as LinkSlashSVG } from '../../../../assets/icon/link-slash.svg';
import { ReactComponent as MagnifyingGlassSVG } from '../../../../assets/icon/magnifying-glass.svg';
import { ReactComponent as SignalStreamSVG } from '../../../../assets/icon/signal-stream.svg';

import Brick from '../../../../sharedComponents/brick';
import Typography from '../../../../sharedComponents/typography';
import { Button } from '../../../../sharedComponents/button';
import { DataTableWidget } from '../../../../sharedComponents/dataTableWidget';

import LinkModal from './LinkModal';
import UnLinkModal from './UnLinkModal';
import FindDevicesModal from './FindDevicesModal';

import {
    fetchKasaDevices,
    fetchKasaAccounts,
    getKasaLinkAccounts,
    getKasaUnLinkAccounts,
    getInsertKasaDevices,
    insertToSystem,
} from './services';

import {
    renderEmail,
    renderHS110s,
    renderHS300s,
    renderKP115s,
    renderRemainingCapacity,
    renderSocket,
    renderTimezone,
} from './utils/accountTableListUtils';

import {
    renderDeviceMac,
    renderKasaAccount,
    renderModel,
    renderStatus,
    renderVendor,
} from './utils/deviceTableListUtils';

import SkeletonLoader from '../../../../components/SkeletonLoader';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../style.css';
import '../styles.scss';

const Provision = () => {
    const { bldgId } = useParams();

    // User Permissions
    const [userPermission] = useAtom(userPermissionData);
    const isUserAdmin = userPermission?.is_admin ?? false;
    const isSuperUser = userPermission?.is_superuser ?? false;
    const isSuperAdmin = isUserAdmin || isSuperUser;
    const canUserCreate = userPermission?.permissions?.permissions?.advanced_active_device_permission?.create ?? false;

    // Linked Accounts
    const [accountsList, setAccountsList] = useState([]);
    const [totalAccounts, setTotalAccounts] = useState(0);
    const [isFetchingAccountList, setFetchingAccountList] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(5);

    // Modal states
    const [showlink, setShowLink] = useState(false);
    const [checkFind, setCheckFind] = useState(true);
    const [showUnlink, setShowUnLink] = useState(false);
    const [showFind, setShowFind] = useState(false);
    const [kasaAccountIds, setKasaAccountIds] = useState([]);

    const handleLinkClose = () => {
        setShowLink(false);
        setError(false);
    };

    const handleUnLinkClose = () => {
        setShowUnLink(false);
        setError(false);
    };

    const handleFindClose = () => setShowFind(false);

    const [totalReadyData, setTotalReadyData] = useState(0);

    const [availableDevices, setAvailableDevices] = useState(0);
    const [existingDevices, setExistingDevices] = useState(0);
    const [isAddProcessing, setIsAddProcessing] = useState(false);
    const [kasaDevicesList, setKasaDevicesList] = useState([]);

    const [selectedStatus, setSelectedStatus] = useState(0);
    const [auth, setAuth] = useState('');

    const [buildingListData] = useAtom(buildingData);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');
    const [search, setSearch] = useState('');
    const [sortBy, setSortBy] = useState({});

    const [deviceSearch, setDeviceSearch] = useState('');
    const [deviceSortBy, setDeviceSortBy] = useState({});
    const [devicePageNo, setDevicePageNo] = useState(1);
    const [devicePageSize, setDevicePageSize] = useState(20);
    const [selectedAccount, setSelectedAccount] = useState({});

    const handleUnlink = async (accountIds) => {
        let authData = {
            kasa_account_id: accountIds,
            building_id: bldgId,
        };
        await getKasaUnLinkAccounts(authData)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.success === false) {
                        setError(true);
                        setMessage(res.data.message);
                    } else {
                        handleUnLinkClose();
                        let arr = {
                            kasa_account_ids: [],
                            find_new: false,
                        };
                        getKasaAccount();
                        getKasaDevices(arr);
                    }
                }
            })
            .catch((error) => {
                setError(true);
            });
    };

    const handleFindDevices = (accountIds) => {
        if (accountIds.length === 0) {
            setShowFind(true);
            setCheckFind(true);
        } else {
            let arr = {
                kasa_account_ids: accountIds,
                find_new: true,
            };
            setSelectedAccount(arr);
            setKasaAccountIds(accountIds);
            setShowFind(false);
        }
    };

    useEffect(() => {
        if (Object.entries(selectedAccount).length !== 0)
            getKasaDevices(selectedAccount, true, selectedStatus === 0 ? 'Found' : 'Completed');
    }, [selectedAccount, devicePageNo, devicePageSize, deviceSearch, deviceSortBy, selectedStatus]);

    const handleAuthorize = async (email, password) => {
        let authData = {
            username: email,
            password: password,
            building_id: bldgId,
        };
        await getKasaLinkAccounts(authData)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.success === false) {
                        setError(true);
                        setMessage(res.data.message);
                    } else {
                        handleLinkClose();
                        localStorage.setItem('kasa_id', res.data.id);
                        setAuth(res.data.id);
                    }
                }
            })
            .catch((error) => {});
    };

    const [showAddMsg, setShowAddMsg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleDone = async (kasa_account_ids) => {
        setIsLoading(true);
        setCheckFind(true);
        let arr = {
            kasa_account_id: kasa_account_ids,
            building_id: bldgId,
        };
        await insertToSystem(arr)
            .then((res) => {
                if (res.data.success === true) setShowAddMsg(true);

                setIsLoading(false);
                let arr = {
                    kasa_account_ids,
                    find_new: true,
                };
                getKasaDevices(arr, false);
            })
            .catch((error) => {
                setError(true);
                setIsLoading(false);
                setCheckFind(false);
            })
            .finally(() => {
                setKasaAccountIds([]);
            });
    };

    const handleAddDevice = async (e, kasa_account_id, device_id) => {
        let authData = {
            building_id: bldgId,
            device_id: device_id,
            kasa_account_id: kasa_account_id,
        };
        await getInsertKasaDevices(authData)
            .then((res) => {
                if (res.status === 200) {
                    getKasaDevices();
                }
            })
            .catch((error) => {
                setError(true);
            });
    };

    useEffect(() => {
        setShowLink(false);
        const updateBreadcrumbStore = () => {
            BreadcrumbStore.update((bs) => {
                let newList = [
                    {
                        label: 'Smart Plugs',
                        path: `/settings/smart-plugs/${bldgId}`,
                        active: false,
                    },
                    {
                        label: 'Provisioning',
                        path: '/settings/smart-plugs/provision',
                        active: true,
                    },
                ];
                bs.items = newList;
            });
            ComponentStore.update((s) => {
                s.parent = 'building-settings';
            });
        };
        updateBreadcrumbStore();
    }, []);

    const getKasaAccount = async () => {
        setFetchingAccountList(true);
        setAccountsList([]);

        const sorting = sortBy.method &&
            sortBy.name && {
                order_by: sortBy.name === undefined ? 'email' : sortBy.name,
                sort_by: sortBy.method === undefined ? 'ace' : sortBy.method,
            };

        await fetchKasaAccounts(pageNo, pageSize, bldgId, search, {
            ...sorting,
        })
            .then((res) => {
                const { success, data, total_data } = res?.data;

                setTotalAccounts(total_data ?? 0);

                if (success && data && data.length !== 0) {
                    let newAccountList = [];

                    data.forEach((el) => {
                        newAccountList.push({
                            id: el?.id,
                            email: el?.email,
                            HS110s: el?.HS110s,
                            KP115s: el?.KP115s,
                            HS300s: el?.HS300s,
                            Socket: el?.Socket,
                            Remaining_Capacity: 256 - el?.Socket,
                        });
                    });

                    setAccountsList(newAccountList);
                } else {
                    UserStore.update((s) => {
                        s.showNotification = true;
                        s.notificationMessage = 'Failed to fetch Kasa accounts.';
                        s.notificationType = 'error';
                    });
                }
            })
            .catch((error) => {
                UserStore.update((s) => {
                    s.showNotification = true;
                    s.notificationMessage = 'Failed to fetch Kasa accounts due to Internal Server Error!';
                    s.notificationType = 'error';
                });
            })
            .finally(() => {
                setFetchingAccountList(false);
            });
    };

    const getKasaDevices = async (details, path, statusType = 'Found') => {
        setIsAddProcessing(true);
        setKasaDevicesList([]);

        const sort_by = deviceSortBy.method ?? 'ace';
        const ordered_by = deviceSortBy.name ?? 'device_mac';

        await fetchKasaDevices({
            bldgId,
            deviceSearch,
            statusType,
            pageNo: devicePageNo,
            pageSize: devicePageSize,
            sortBy: sort_by,
            orderedBy: ordered_by,
            payload: details,
        })
            .then((res) => {
                const response = res?.data;

                if (response?.success) {
                    if (path) setCheckFind(false);

                    if (response?.data && response?.data.length !== 0) {
                        const devicesList = response?.data.map((el, index) => ({
                            ...el,
                            is_super_user: isSuperAdmin,
                            index: index,
                        }));
                        setKasaDevicesList(devicesList);
                    }

                    if (response?.found) setAvailableDevices(+response?.found);
                    if (response?.completed) setExistingDevices(+response?.completed);

                    setTotalReadyData(statusType === 'Found' ? response?.found : response?.completed);
                }
            })
            .catch((error) => {})
            .finally(() => {
                setIsAddProcessing(false);
            });
    };

    useEffect(() => {
        getKasaAccount();
    }, [auth, search, sortBy, pageNo, pageSize]);

    useEffect(() => {
        if (bldgId && buildingListData.length !== 0) {
            const bldgObj = buildingListData.find((el) => el?.building_id === bldgId);
            if (bldgObj?.building_id)
                updateBuildingStore(
                    bldgObj?.building_id,
                    bldgObj?.building_name,
                    bldgObj?.timezone,
                    bldgObj?.plug_only
                );
        }
    }, [buildingListData, bldgId]);

    //Linked Accounts
    const currentRow = () => {
        return accountsList;
    };

    //Linked Devices
    const currentRowDevices = () => {
        return kasaDevicesList;
    };

    const accountsHeaderProps = [
        {
            name: 'Email',
            accessor: 'email',
            callbackValue: renderEmail,
            onSort: (method, name) => setSortBy({ method, name }),
        },
        {
            name: 'Timezone',
            accessor: 'timezone',
            callbackValue: renderTimezone,
        },
        {
            name: 'HS110s',
            accessor: 'HS110s',
            callbackValue: renderHS110s,
            onSort: (method, name) => setSortBy({ method, name }),
        },
        {
            name: 'KP115s',
            accessor: 'KP115s',
            callbackValue: renderKP115s,
            onSort: (method, name) => setSortBy({ method, name }),
        },
        {
            name: 'HS300s',
            accessor: 'HS300s',
            callbackValue: renderHS300s,
            onSort: (method, name) => setSortBy({ method, name }),
        },
        {
            name: 'Sockets',
            accessor: 'Socket',
            callbackValue: renderSocket,
            onSort: (method, name) => setSortBy({ method, name }),
        },
        {
            name: 'Remaining Capacity',
            accessor: 'Remaining_Capacity',
            callbackValue: renderRemainingCapacity,
            onSort: (method, name) => setSortBy({ method, name }),
        },
    ];

    //Linked Devices
    const headerProps2 = [
        {
            name: 'Status',
            accessor: 'status',
            callbackValue: renderStatus,
        },
        {
            name: 'Device ID',
            accessor: 'device_mac',
            callbackValue: renderDeviceMac,
        },
        {
            name: 'Vendor',
            accessor: 'vendor',
            callbackValue: renderVendor,
        },
        {
            name: 'Model',
            accessor: 'model',
            callbackValue: renderModel,
        },
        {
            name: 'Kasa Account',
            accessor: 'kasa_account',
            callbackValue: renderKasaAccount,
        },
    ];

    const pageListSizes = [
        {
            label: '5 Rows',
            value: '5',
        },
        {
            label: '10 Rows',
            value: '10',
        },
        {
            label: '15 Rows',
            value: '15',
        },
    ];

    return (
        <React.Fragment>
            <Typography.Header size={Typography.Sizes.lg}>Add Devices</Typography.Header>

            <Brick sizeInRem={1.5} />

            {!canUserCreate && !isSuperAdmin ? (
                <Typography.Body size={Typography.Sizes.md}>
                    You don't have the permission to view this page.
                </Typography.Body>
            ) : (
                <>
                    <div className="d-flex justify-content-between align-items-center">
                        <Typography.Header size={Typography.Sizes.md}>{`Linked TP-Link Accounts`}</Typography.Header>

                        <div className="d-flex" style={{ gap: '0.75rem' }}>
                            <Button
                                label="Unlink Account"
                                size={Button.Sizes.md}
                                type={Button.Type.secondaryGrey}
                                icon={<LinkSlashSVG />}
                                iconAlignment={Button.IconAlignment.left}
                                onClick={() => {
                                    setShowUnLink(true);
                                    setShowAddMsg(false);
                                }}
                            />
                            <Button
                                label="Link Account"
                                size={Button.Sizes.md}
                                type={Button.Type.secondaryGrey}
                                icon={<LinkSVG />}
                                iconAlignment={Button.IconAlignment.left}
                                onClick={() => {
                                    setShowLink(true);
                                    setShowAddMsg(false);
                                }}
                            />
                        </div>
                    </div>

                    <Brick sizeInRem={1.5} />

                    <DataTableWidget
                        id="linked_account"
                        isLoading={isFetchingAccountList}
                        isLoadingComponent={<SkeletonLoader noOfColumns={accountsHeaderProps.length} noOfRows={5} />}
                        onSearch={(query) => {
                            setPageNo(1);
                            setSearch(query);
                        }}
                        rows={currentRow()}
                        searchResultRows={currentRow()}
                        headers={accountsHeaderProps}
                        buttonGroupFilterOptions={[]}
                        disableColumnDragging={true}
                        currentPage={pageNo}
                        onChangePage={setPageNo}
                        pageSize={pageSize}
                        onPageSize={setPageSize}
                        pageListSizes={pageListSizes}
                        totalCount={(() => {
                            return totalAccounts;
                        })()}
                    />

                    <Brick sizeInRem={0.5} />
                    <hr />
                    <Brick sizeInRem={0.5} />

                    <div className="d-flex align-items-center justify-content-between">
                        <Typography.Header size={Typography.Sizes.md}>Devices</Typography.Header>

                        <div className="d-flex" style={{ gap: '0.75rem' }}>
                            <Button
                                label="Find Devices"
                                size={Button.Sizes.md}
                                type={Button.Type.secondaryGrey}
                                icon={<MagnifyingGlassSVG />}
                                iconAlignment={Button.IconAlignment.left}
                                onClick={() => {
                                    setShowFind(true);
                                    setShowAddMsg(false);
                                }}
                            />
                            <Button
                                label="Add to System"
                                size={Button.Sizes.md}
                                type={Button.Type.primary}
                                icon={<SignalStreamSVG />}
                                iconAlignment={Button.IconAlignment.left}
                                onClick={() => handleDone(kasaAccountIds)}
                                disabled={checkFind}
                            />
                        </div>
                    </div>

                    <Brick sizeInRem={1.5} />

                    <DataTableWidget
                        id="devices_linked"
                        isLoading={isAddProcessing}
                        isLoadingComponent={<SkeletonLoader noOfColumns={headerProps2.length} noOfRows={15} />}
                        onSearch={(query) => {
                            setDevicePageNo(1);
                            setDeviceSearch(query);
                        }}
                        onStatus={(value) => {
                            setDevicePageNo(1);
                            setSelectedStatus(value);
                        }}
                        buttonGroupFilterOptions={[
                            { label: `Available (${availableDevices})` },
                            { label: `Existing Devices (${existingDevices})` },
                        ]}
                        rows={currentRowDevices()}
                        disableColumnDragging={true}
                        searchResultRows={currentRowDevices()}
                        headers={headerProps2}
                        currentPage={devicePageNo}
                        onChangePage={setDevicePageNo}
                        pageSize={devicePageSize}
                        onPageSize={setDevicePageSize}
                        totalCount={(() => {
                            return totalReadyData;
                        })()}
                    />

                    <Row>
                        <Col lg={1}></Col>
                        <Col lg={10}>
                            <Alert variant="success" isOpen={showAddMsg}>
                                <div>
                                    <CircleCheckSVG
                                        className="ml-2"
                                        style={{ marginRight: '0.25rem', color: 'white' }}
                                    />
                                    Devices Added to system
                                </div>
                            </Alert>
                        </Col>
                    </Row>
                </>
            )}

            <LinkModal
                showlink={showlink}
                handleLinkClose={handleLinkClose}
                error={error}
                message={message}
                handleAuthorize={handleAuthorize}
            />

            <UnLinkModal
                showUnlink={showUnlink}
                handleUnLinkClose={handleUnLinkClose}
                error={error}
                message={message}
                handleUnlink={handleUnlink}
                linkedAccount={accountsList}
            />

            <FindDevicesModal
                showFind={showFind}
                handleFindClose={handleFindClose}
                error={error}
                message={message}
                handleFindDevices={handleFindDevices}
                linkedAccount={accountsList}
            />
        </React.Fragment>
    );
};

export default Provision;
